import React, { Component } from "react";
// import { add, format } from "date-fns";

// import B2BWidgets from "../../../b2bwidgets/index";
// import DesktopCalender from "../../../calendar/DesktopCalender";
import autoSuggest from "../../../../lib/holidayautosuggest";
import categoryList from "../../../../lib/categoryList";
import cf from "../../../../lib/utils/common";
import { getStorage, setStorage } from "../../../../lib/utils/localStorage";

// static 5 cities
const popularCities = [
  {
    destination_id: "11663",
    search_text: "Mumbai",
    location_type: 2,
  },
  {
    destination_id: "11667",
    search_text: "New Delhi",
    location_type: 2,
  },
  {
    destination_id: "11634",
    search_text: "Chennai",
    location_type: 2,
  },
  {
    destination_id: "12633",
    search_text: "Dubai",
    location_type: 2,
  },
  {
    destination_id: "10516",
    search_text: "Paris",
    location_type: 2,
  },
];

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
};
const fetchData = async (query, callBackFunc) => {
  if (query.length > 2) {
    const res = await autoSuggest({ searchText: query });
    callBackFunc(res);
  } else {
    callBackFunc(null);
  }
};
const debouncedFetchData = debounce((query, cb) => {
  fetchData(query, cb);
}, 400);

const categoryArray = [];

export default class Theme2 extends Component {
  constructor(props) {
    super(props);
    this.searchBoxRef = React.createRef();
    this.advanceSearchWrapper = React.createRef();
    this.guestDetailWrapper = React.createRef();
    this.categoryRef = React.createRef();
    this.monthRef = React.createRef();
    this.state = {
      changeActivityName: new URLSearchParams(window.location.search).has(
        "searchby"
      )
        ? new URLSearchParams(window.location.search).get("searchby").toString()
        : "",
      activityName: new URLSearchParams(window.location.search).has("searchby")
        ? new URLSearchParams(window.location.search).get("searchby").toString()
        : "",
      isAutoSuggestVisible: false,
      isCategoryVisible: false,
      isMonthVisible: false,
      // checkInOutValue: "",
      // checkInValue: null,
      // checkOutValue: null,
      // isCalendarVisible: false,
      autoSuggestCountryData: [],
      autoSuggestCityData: popularCities,
      selectedAutoSuggestData: {
        category: new URLSearchParams(window.location.search).has("category")
          ? new URLSearchParams(window.location.search).get("category") !== ""
            ? new URLSearchParams(window.location.search)
                .get("category")
                .split(",")
                .map((ele) => categoryArray.push({ name: ele }))
            : []
          : [],
        name: new URLSearchParams(window.location.search).has("searchby")
          ? new URLSearchParams(window.location.search)
              .get("searchby")
              .toString()
          : "",
        type: new URLSearchParams(window.location.search).has("type")
          ? new URLSearchParams(window.location.search).get("type").toString()
          : "",
      },
      isError: false,
      isDateError: false,
      lastArrowAction: "",
      selectedAutoSuggestIndex: -1,
      selectedMonthIndex: -1,
      noresults: false,
      categoryList: [],
      categoryValue: [],
      categoryOriginalArray: [],
      monthOriginalArray: cf.getMonthsRange(12),
      monthData: cf.getMonthsRange(12),
      monthValue: new URLSearchParams(window.location.search).has("month")
        ? new URLSearchParams(window.location.search).get("month").toString()
        : "",
      getrecentSearchList: null,
      getrecentSearchListLocal: null,
      search_key: "holidaySearchKey",
      search_key_all: "holidaySearchKeyAll",
      btnClicked: false,
      // advSearch: false,
      // isOneStar: false,
      // isTwoStar: false,
      // isThreeStar: false,
      // isFourStar: false,
      // isFiveStar: false,
      // ratingSelected: [],
      // guest_id: "",
      // guestError: false,
    };
  }
  onClickAdvSearch = () => {
    if (!this.state.advSearch) {
      this.setState({ advSearch: true });
    } else {
      this.setState({ advSearch: false });
    }
  };
  componentDidMount() {
    // Local storage recent search ========>

    if (true) {
      let LocalSearch = getStorage(this.state.search_key);

      let LocalSearchAll = getStorage(this.state.search_key_all);

      if (LocalSearchAll != null) {
        this.setState({ getrecentSearchListLocal: JSON.parse(LocalSearchAll) });
      }
      if (LocalSearch != null && LocalSearchAll == null) {
        this.setState({ getrecentSearchListLocal: [JSON.parse(LocalSearch)] });
      }
    }
    try {
      categoryList().then((res) => {
        if (res?.status) {
          this.setState({
            categoryList: res.data,
            categoryOriginalArray: res.data,
          });
        } else {
          // Handle the 404 error here
          // For example, you can display an error message or redirect to a different page
          console.log("404 error occurred");
        }
      });
    } catch (e) {
      console.log(e);
    }

    if (new URLSearchParams(window.location.search).has("searchby")) {
      let text = new URLSearchParams(window.location.search)
        .get("searchby")
        .toString();
      if (text !== "") {
        autoSuggest({
          searchText: text,
        }).then((data) => {
          if (data?.data?.length > 0) {
            // console.log(data);
            this.setState({
              categoryList: data.data[0].category,
              monthData: data.data[0].month,
            });
          }
        });
      }
    }
    this.setState({ categoryValue: categoryArray });
    const activityname = new URLSearchParams(window.location.search).get(
      "activityname"
    );
    // const holidayName = new URLSearchParams(window.location.search).has('searchby')
    // this.setState({activityname: holidayName ? new URLSearchParams(window.location.search).get('searchby').toString() : ''})
    // this.onChangeCheckInOutValue(new Date());
    if (activityname != null) {
      debouncedFetchData(activityname, (data) => {
        if (
          data &&
          data.data
          // typeof data.data.searchBycity === "object"
        ) {
          const cityData = [...data.data];
          const selectedCity = cityData.filter((city) => {
            return city.name == activityname;
          })[0];
          this.setState({
            autoSuggestCityData: cityData,
            isAutoSuggestVisible: false,
            activityName: selectedCity.name,
            selectedAutoSuggestData: selectedCity,
            isError: false,
            noresults: false,
          });
        }
        if (data.status && data.data.length === 0) {
          this.setState({
            noresults: true,
            autoSuggestCityData: [],
            isAutoSuggestVisible: true,
          });
        }
      });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  //Add client
  // adGuestId = (id) => {
  //   this.setState({ guest_id: id }, () => {
  //     if (this.state.guestError === true) {
  //       this.setState({ guestError: cf.isValueEmpty(id) ? true : false })
  //     }
  //     this.setState({ guest_id: id });
  //   });
  // }
  // rmGuestId = () => {
  //   this.setState({ guest_id: "" });
  // };
  // onChangeCheckInOutValue = (dateValue) => {
  //   console.log("date=value", dateValue);
  //   this.setState({
  //     checkInValue: cf.getDateWithFormat(
  //       // new Date(dateValue),
  //       add(new Date(dateValue), { days: 7 }),
  //       this?.props?.dateFormat
  //     ),
  //     checkOutValue: cf.getDateWithFormat(
  //       add(new Date(dateValue), { days: 8 }),
  //       this?.props?.dateFormat
  //     ),
  //     checkInOutValue: cf.getDateWithFormat(dateValue, this?.props?.dateFormat),
  //     isDateError: false,
  //   });
  // };

  onChanegeSetActivityName = (value) => {
    this.setState({
      changeActivityName: value,
      activityName: "",
      isError: false,
    });
    if (value === "") {
      this.setState({
        autoSuggestCityData: [],
        // isAutoSuggestVisible: false,
        selectedAutoSuggestData: [],
      });
    } else {
      debouncedFetchData(value, (data) => {
        if (
          data &&
          data.data
          // data.data.searchBycity &&
          // typeof data.data.searchBycity === "object"
        ) {
          this.setState({
            autoSuggestCityData: [...data.data],
            isAutoSuggestVisible: true,
            noresults: false,
          });
        }
        if (data?.status && data?.data?.length === 0) {
          this.setState({
            noresults: true,
            autoSuggestCityData: [],
            isAutoSuggestVisible: true,
          });
        }
      });
    }
  };

  onClickSearchActivity = () => {
    if (this.state.activityName) {
      let LocalSearch = getStorage(this.state.search_key);
      let LocalSearchAll = JSON.parse(getStorage(this.state.search_key_all));
      const searchItem = JSON.parse(LocalSearch);

      if (LocalSearch) {
        let searchDataArr = LocalSearchAll ? JSON.parse(getStorage(this.state.search_key_all)) : [];
        
        let newSearchItem = JSON.parse(JSON.stringify(this.state.selectedAutoSuggestData))
        if (!LocalSearchAll || searchDataArr.length === 0) {
            searchDataArr.unshift(newSearchItem);
        } else {
            const existingNames = searchDataArr.map(item => item.name);
            
            if (!existingNames.includes(newSearchItem.name)) {
                searchDataArr.unshift(newSearchItem);
            }
            if (searchDataArr.length > 5) {
                searchDataArr.pop();
            }
        }
        setStorage(this.state.search_key_all, JSON.stringify(searchDataArr));
        setStorage(this.state.search_key, JSON.stringify([searchItem]));
    }
     else {
        var searchData = [];
        let data = {
          searchby: this.state.activityName,
        };
        // searchData.push(data); // add the object to the array
        searchData.unshift(data);
        var searchDartaStrig = JSON.stringify(this.state.selectedAutoSuggestData);
        setStorage(this.state.search_key, searchDartaStrig);
      }

      let data = {
        searchby: this.state.activityName,
      };

      setStorage(this.state.search_key, JSON.stringify(this.state.selectedAutoSuggestData));
    }
    // if (
    //   !(
    //     this.state.selectedAutoSuggestData &&
    //     this.state.selectedAutoSuggestData.name
    //   )
    // ) {
    //   this.setState({ isError: true });
    // }
    // if (this.state.checkInOutValue == "") {
    //   this.setState({ isDateError: true });
    // }
    // if (this.props.isOffline === true && this.state.guest_id === '') {
    //   this.setState({ guestError: true })
    // } else {
    //   this.setState({ guestError: false })
    // }
    // if (
    //   this.state.selectedAutoSuggestData &&
    //   this.state.selectedAutoSuggestData.name
    //   // this.state.checkInOutValue
    // ) {
    let urlQuery = {
      searchby: this.state.activityName,
      type:
        this.state.selectedAutoSuggestData.hasOwnProperty("type") &&
        this.state.selectedAutoSuggestData?.type != ""
          ? this.state.selectedAutoSuggestData.type
          : this.state.monthValue != ""
          ? 1
          : "",
      category: this.state.categoryValue.map(({ name }) => `${name}`).join(","),
      month: this.state.monthValue,
      // checkin: format(new Date(this.state.checkInValue), "yyyy-MM-dd"),
      // checkout: format(new Date(this.state.checkOutValue), "yyyy-MM-dd"),
      // from: 1,
      // to: 30,
      // search_type: this.state.selectedAutoSuggestData.location_type,
      // search_text: this.state.selectedAutoSuggestData.destination_id,
      // currency: "AED",
      // rating: [...new Set(this.state.ratingSelected)].join(','),
      // guestId: this.state.guest_id
    };
    console.log(urlQuery);
    this.setState({
      btnClicked: true,
    });
    cf.redirectTo("/holiday/search", urlQuery);
    // if (this.props.isOffline === true) {
    // if (this.state.guest_id !== '') {
    //   const searchParams = new URLSearchParams(urlQuery)
    //   const queryString = searchParams.toString()
    //   // window.location = "/offlinesearch/activity?" + queryString
    //   window.location =  "/holiday/search"
    // }
    // window.open(`${window.location.origin}` + "/offlinesearch/activity" , "_self");
    // cf.redirectTo("/offlinesearch/activity", urlQuery)

    // } else {
    // window.location =  "/holiday/search"
    // }
    // }
    // else {
    //   console.log('please select guest id')
    // }
  };
  // onClickSetActivityName = (element) => {
  //   this.setState({
  //     activityName: element.name,
  //     selectedAutoSuggestData: element,
  //     isError: false,
  //   });
  // };
  onInputFocus = (fieldName) => {
    console.log("field name ==", fieldName);
    if (fieldName == "categories") {
      this.setState({
        isAutoSuggestVisible: false,
        isCategoryVisible: true,
        isMonthVisible: false,
      });
    } else if (fieldName == "month") {
      this.setState({
        isAutoSuggestVisible: false,
        isCategoryVisible: false,
        isMonthVisible: true,
      });
    } else if (fieldName == "search") {
      this.setState({ isMonthVisible: false });
    }
  };

  keyPress = (action) => {
    console.log("auto suggest holiday ==", action);
    switch (action) {
      case "ArrowDown":
        // console.log("keyPress fn down called ", action);
        let children = document.getElementsByName(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        );
        console.log("children ==?>>", children);

        let selectIndex = 0;
        selectIndex =
          this.state.selectedAutoSuggestIndex == children.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex + 1;
        // console.log("children of auto-suggest", children);
        for (let index = 0; index < children.length; index++) {
          let childElement = children[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndex === index ? "#ccc" : "#fff";
          }
        }
        this.setState({
          selectedAutoSuggestIndex: selectIndex,
          lastArrowAction: "ArrowDown",
        });
        break;
      case "ArrowUp":
        console.log("keyPress fn up called ", action);
        let childrens = document.getElementsByName(
          "ACTIVITY-AUTO-SUGGEST-DATA-LIST"
        );
        let selectIndexUp = 0;
        selectIndexUp =
          this.state.selectedAutoSuggestIndex == childrens.length - 1
            ? 0
            : this.state.selectedAutoSuggestIndex == -1
            ? 0
            : this.state.selectedAutoSuggestIndex - 1;
        console.log("children of auto-suggest", childrens);
        for (let index = 0; index < childrens.length; index++) {
          let childElement = childrens[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndexUp === index ? "#ccc" : "#fff";
          }
        }
        // this.setState((prevState) => ({
        //   selectedAutoSuggestIndex:
        //     prevState.selectedAutoSuggestIndex > 0
        //       ? prevState.lastArrowAction=="ArrowDown"?prevState.selectedAutoSuggestIndex - 1:prevState.selectedAutoSuggestIndex
        //       : prevState.selectedAutoSuggestIndex+1,
        //   lastArrowAction: "ArrowUp",
        // }));
        this.setState({
          selectedAutoSuggestIndex: selectIndexUp,
          lastArrowAction: "ArrowUp",
        });
        break;
      case "Enter":
      case "Tab":
        let indexToSelect = 0;
        if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowUp"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        } else if (
          this.state.selectedAutoSuggestIndex != 0 &&
          this.state.lastArrowAction == "ArrowDown"
        ) {
          indexToSelect = this.state.selectedAutoSuggestIndex;
        }
        // const indexToSelect = this.state.selectedAutoSuggestIndex===0?0:this.state.lastArrowAction?"ArrowUp"?this.state.selectedAutoSuggestIndex+1:this.state.selectedAutoSuggestIndex-1:0;
        const selectedItem = this.state.autoSuggestCityData[indexToSelect];
        this.setState({
          activityName: selectedItem.name,
          selectedAutoSuggestData: selectedItem,
          isAutoSuggestVisible: false,
          selectedAutoSuggestIndex: indexToSelect,
          lastArrowAction: "",
        });
        break;

      default:
        console.log("default", action);
        break;
    }
  };
  keyPressMonth = (action) => {
    console.log("key action ==", action);
    switch (action) {
      case "ArrowDown":
        // console.log("keyPress fn down called ", action);
        let children = document.getElementById("MONTH-LIST").children;

        let selectIndex = 0;
        selectIndex =
          this.state.selectedMonthIndex == children.length - 1
            ? 0
            : this.state.selectedMonthIndex + 1;
        console.log("children of month list arrow down ===", children);
        for (let index = 0; index < children.length; index++) {
          let childElement = children[index];
          console.log("child element ==", childElement);
          let id = childElement.id;
          console.log("child id ==", id);
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndex === index ? "#ccc" : "#fff";
          }
        }
        this.setState({
          selectedMonthIndex: selectIndex,
          lastArrowAction: "ArrowDown",
        });
        break;
      case "ArrowUp":
        console.log("keyPress fn up called ", action);
        let childrens = document.getElementById("MONTH-LIST").children;
        let selectIndexUp = 0;
        selectIndexUp =
          this.state.selectedMonthIndex == childrens.length - 1
            ? 0
            : this.state.selectedMonthIndex == -1
            ? 0
            : this.state.selectedMonthIndex - 1;
        console.log("children of auto-suggest", childrens);
        for (let index = 0; index < childrens.length; index++) {
          let childElement = childrens[index];
          let id = childElement.id;
          if (id != null && id != "") {
            childElement.style.backgroundColor =
              selectIndexUp === index ? "#ccc" : "#fff";
          }
        }
        this.setState({
          selectedMonthIndex: selectIndexUp,
          lastArrowAction: "ArrowUp",
        });
        break;
      case "Enter":
      case "Tab":
        let indexToSelect = 0;
        if (
          this.state.selectedMonthIndex != 0 &&
          this.state.lastArrowAction == "ArrowUp"
        ) {
          indexToSelect = this.state.selectedMonthIndex;
        } else if (
          this.state.selectedMonthIndex != 0 &&
          this.state.lastArrowAction == "ArrowDown"
        ) {
          indexToSelect = this.state.selectedMonthIndex;
        }
        // const indexToSelect = this.state.selectedAutoSuggestIndex===0?0:this.state.lastArrowAction?"ArrowUp"?this.state.selectedAutoSuggestIndex+1:this.state.selectedAutoSuggestIndex-1:0;
        const selectedItem = this.state.monthData[indexToSelect];
        this.setState({
          monthValue: selectedItem.month,
          isMonthVisible: false,
          selectedMonthIndex: indexToSelect,
          lastArrowAction: "",
        });
        break;

      default:
        console.log("default", action);
        break;
    }
  };

  scrollToMyRef = () => {
    this.searchBoxRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  // onClickTraveller = () => {
  //   if (!this.state.openTraveler) {
  //     this.setState({ openTraveler: true })
  //   } else {
  //     this.setState({ openTraveler: false })
  //   }
  // }

  // handleStarCheck = (event) => {
  //   this.setState({ [event.target.name]: event.target.checked })
  // }

  // ratingHandler = (e) => {
  //   console.log('rating selected array =-->>', this.state.ratingSelected)
  //   const { value, checked } = e.target
  //   const { ratingSelected } = this.state

  //   if (checked) {
  //     this.setState({
  //       ratingSelected: [...ratingSelected, value]
  //     })
  //   } else {
  //     this.setState({
  //       ratingSelected: ratingSelected.filter(e => e !== value)
  //     })
  //   }
  // }

  handleClickOutside = (e) => {
    if (
      this.advanceSearchWrapper.current &&
      !this.advanceSearchWrapper.current.contains(e.target)
    ) {
      this.setState({ advSearch: false });
    }
    if (
      this.guestDetailWrapper.current &&
      !this.guestDetailWrapper.current.contains(e.target)
    ) {
      this.setState({ openTraveler: false });
    }
    if (
      this.categoryRef.current &&
      !this.categoryRef.current.contains(e.target)
    ) {
      this.setState({ isCategoryVisible: false });
    }
    if (this.monthRef.current && !this.monthRef.current.contains(e.target)) {
      this.setState({ isMonthVisible: false });
    }
  };

  categoryHandler = (e, ele) => {
    if (e.target.checked) {
      const isFound = this.state.categoryValue.some((element) => {
        if (element.name === ele.name) {
          return true;
        }
        return false;
      });
      if (!isFound) {
        this.setState({ categoryValue: [...this.state.categoryValue, ele] });
      }
    } else {
      this.setState({
        categoryValue: this.state.categoryValue.filter((value) => {
          return value.name !== ele.name;
        }),
      });
    }
  };

  categorySelected = (item) => {
    let value = false;
    this.state.categoryValue.map((ele) => {
      if (ele.name === item.name) {
        value = true;
      }
    });
    return value;
  };

  render() {
    const uniqueCategories = this.state.categoryValue.filter(
      (category, index, self) =>
        index === self.findIndex((t) => t.name === category.name)
    );
    return (
      <div
        className={
          this.props.isSrp
            ? this.props.cssfile.holidayMain_srp
            : " container-1242 mx-auto"
        }
        ref={this.searchBoxRef}
        onClick={(e) => {
          e.stopPropagation();
          if (this.state.isAutoSuggestVisible) {
            this.setState({
              isAutoSuggestVisible: false,
            });
          }
          // if (this.state.isCalendarVisible) {
          //   this.setState({
          //     isCalendarVisible: false,
          //   });
          // }
        }}
      >
        <div
          className={
            this.props.isSrp
              ? this.props.cssfile.activity_search_wid_container_srp
              : this.props.cssfile.activity_search_wid_container
          }
        >
          <div className="activity_search_wid_main">
            <div className={this.props.cssfile.activity_way_title}>
              {/* <h2>Activities</h2> */}
            </div>
            {/* {this.props?.sessionData?.type === 'B2B' &&

              <B2BWidgets {...this.state}
                styles={this.props.b2bStyles}
                type={'activity'}
                onClickTraveller={this.onClickTraveller}
                onClickAdvSearch={this.onClickAdvSearch}
                addGuestId={this.adGuestId}
                removeGuestId={this.rmGuestId}
                ratingHandler={this.ratingHandler}
                handleStarCheck={this.handleStarCheck}
                isOffline={this.props.isOffline}
                listWrapper={this.advanceSearchWrapper}
                guestWrapper={this.guestDetailWrapper}
                errorMessage={"Select Traveler"}
                isError={this.state.guestError}
              />} */}
            <div
              className={this.props.cssfile.activity_search_details_container}
            >
              <div className={this.props.cssfile.activity_search_details_main}>
                <div className={this.props.cssfile.activity_search_details_row}>
                  <div
                    className={
                      this.props.cssfile.activity_search_column +
                      " " +
                      this.props.cssfile.activity_search_name
                    }
                  >
                    <div
                      id="search-widget-main"
                      className={
                        this.props.cssfile.activity_search_wid_inputfield
                      }
                      onClick={(e) => {
                        this.scrollToMyRef();
                        e.stopPropagation();
                        this.setState((prevState) => {
                          return {
                            ...prevState,
                            isAutoSuggestVisible:
                              !prevState.isAutoSuggestVisible,
                            isCalendarVisible: false,
                            activityName: "",
                            autoSuggestCityData: [],
                            categoryList: this.state.categoryOriginalArray,
                            monthData: this.state.monthOriginalArray,
                            monthValue: "",
                            isCategoryVisible: false,
                            noresults: false,
                            categoryValue: [],
                          };
                        });
                      }}
                    >
                      <label>{"Destination"}</label>
                      {/* <span>
                      <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        width="200px"
                        height="200px"
                        viewBox="0 0 200 200"
                      >
                        <defs></defs>
                        <g transform="matrix(1, 0, 0, 1, 5.362504, 3.483317)">
                          <path
                            fill="#266BAC"
                            d="M 137.761 105.745 L 79.925 163.779 C 76.558 166.948 71.407 166.948 68.238 163.779 C 65.07 160.61 65.07 155.262 68.238 152.092 L 120.33 100 L 68.238 47.909 C 65.07 44.542 65.07 39.392 68.238 36.223 C 71.407 33.054 76.557 33.054 79.925 36.223 L 137.761 94.059 C 140.93 97.426 140.93 102.576 137.761 105.745 Z"
                            style={{
                              transformBox: 'fill-box',
                              transformOrigin: '50% 50%',
                              transform: 'matrix(0, 1, -1, 0, 0.000003, 0)',
                            }}
                          ></path>
                        </g>
                      </svg>
                      </span> */}
                      <div
                        className={
                          this.props.cssfile.activity_search_wid_inputfield_main
                        }

                        // onClick={this.scrollToMyRef}
                      >
                        <input
                          type="text"
                          // readOnly
                          // tabIndex={1}
                          placeholder={`Enter Destination eg. Goa...`}
                          autoComplete={"off"}
                          id="myTextField"
                          className={this.props.cssfile.search_widget_input_new}
                          value={
                            !cf.isValueEmpty(this.state.activityName) &&
                            !this.state.isAutoSuggestVisible
                              ? this.state.activityName
                              : ""
                          }
                          onClick={(e) => {
                            this.scrollToMyRef();
                            e.stopPropagation();
                            this.setState((prevState) => {
                              return {
                                ...prevState,
                                isAutoSuggestVisible:
                                  !prevState.isAutoSuggestVisible,
                                isCalendarVisible: false,
                                activityName: "",
                                autoSuggestCityData: [],
                                categoryList: this.state.categoryOriginalArray,
                                monthData: this.state.monthOriginalArray,
                                monthValue: "",
                                isCategoryVisible: false,
                                noresults: false,
                                categoryValue: [],
                                selectedAutoSuggestData: { name: "" },
                              };
                            });
                          }}
                          // onChange={(event) => {
                          //   event.stopPropagation();
                          //   this.onChanegeSetActivityName(event.target.value);
                          // }}

                          // onFocus={() => {
                          //   // e.stopPropagation();
                          //   // console.log("event on focus pressed", e.key);
                          //   this.onInputFocus("autocomplete");
                          // }}
                          onKeyDown={(e) => {
                            e.stopPropagation();
                            this.keyPress(e.key);
                          }}
                        />

                        {this.state.isError && (
                          <div className={this.props.cssfile.error}>
                            {"Enter Destination"}
                          </div>
                        )}

                        {this.state.isAutoSuggestVisible === true ? (
                          <div
                            className={
                              this.props.cssfile.activity_autocomplete_value
                            }
                          >
                            <div
                              className={
                                this.props.cssfile.input_search_destination
                              }
                            >
                              <svg
                                width="50px"
                                height="50px"
                                viewBox="0,0,256,256"
                              >
                                <g
                                  fill="#979797"
                                  fillRule="nonzero"
                                  stroke="none"
                                  strokeWidth="1"
                                  strokeLinecap="butt"
                                  strokeLinejoin="miter"
                                  strokeMiterlimit="10"
                                  strokeDasharray=""
                                  strokeDashoffset="0"
                                  fontFamily="none"
                                  fontWeight="none"
                                  fontSize="none"
                                  textAnchor="none"
                                >
                                  <g transform="scale(5.12,5.12)">
                                    <path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                                  </g>
                                </g>
                              </svg>
                              <input
                                type="text"
                                tabIndex={1}
                                autoFocus
                                autoComplete={"off"}
                                id="myTextField"
                                placeholder={`Enter destination eg. Goa...`}
                                className={
                                  this.props.cssfile.search_widget_input_new
                                }
                                value={
                                  !cf.isValueEmpty(
                                    this.state.changeActivityName
                                  )
                                    ? this.state.changeActivityName
                                    : ""
                                }
                                onChange={(event) => {
                                  event.stopPropagation();
                                  this.onChanegeSetActivityName(
                                    event.target.value
                                  );
                                }}
                                onClick={(e) => {
                                  this.scrollToMyRef();
                                  e.stopPropagation();
                                  this.setState((prevState) => {
                                    return {
                                      ...prevState,
                                      isAutoSuggestVisible: true,
                                      isCalendarVisible: false,
                                      // activityName: "",
                                      // selectedAutoSuggestData: {name: ''}
                                    };
                                  });
                                }}
                                // onFocus={() => {
                                //   // e.stopPropagation();
                                //   // console.log("event on focus pressed", e.key);
                                //   this.onInputFocus("autocomplete");
                                // }}
                                onKeyDown={(e) => {
                                  e.stopPropagation();
                                  this.keyPress(e.key);
                                }}
                              />
                            </div>
                            {/* <div className={this.props.cssfile.activity_search_new}>
                              <div className={this.props.cssfile.activity_iconsearch}>
                              <svg version="1.1"
                          id="Layer_1"
                          x="0px"
                          y="0px"
                          width="35px"
                          height="35px"
                          viewBox="0 0 35 35"
                          enableBackground="new 0 0 35 35">
                              <path id="Path_164" fill="#3479C5" d="M11.045,0.004C4.943,0.008-0.001,4.958,0.003,11.06S4.957,22.105,11.06,22.102
	c2.33,0,4.601-0.738,6.485-2.107l5.5,5.5c0.668,0.688,1.766,0.701,2.452,0.035c0.687-0.668,0.702-1.766,0.035-2.452
	c-0.013-0.013-0.023-0.022-0.035-0.035l-5.5-5.5c3.587-4.931,2.499-11.836-2.433-15.424C15.671,0.739,13.388-0.001,11.045,0.004z
	 M11.045,18.637c-4.193,0.006-7.596-3.389-7.602-7.582c-0.005-4.193,3.389-7.596,7.582-7.602c4.193-0.005,7.597,3.389,7.602,7.582
	c0.003,2.017-0.797,3.951-2.223,5.378c-1.422,1.421-3.349,2.22-5.359,2.22V18.637z"/>
                              </svg>
                              </div>
                              <div className={this.props.cssfile.activity_citysearch}>
                                <span>City / Destination / Activity</span>
                              </div>
                            </div>
                            <div className={this.props.cssfile.activity_recentsearch}>Recent Search</div> this will be picked later */}
                            {!cf.isValueEmpty(
                              this.state.autoSuggestCountryData
                            ) &&
                              this.state.autoSuggestCountryData.length > 0 &&
                              this.state.countryData.map((element, index) => {
                                return (
                                  <div
                                    className={
                                      this.props.cssfile.mainactivitydrop
                                    }
                                  >
                                    <div
                                      id={"COUNTRY-" + index}
                                      key={element.search_text}
                                      className={
                                        this.props.cssfile
                                          .activity_autocomplete_element
                                      }
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({
                                          activityName: element.name,
                                          isAutoSuggestVisible: false,
                                        });
                                        // this.onClickSetActivityName(element);
                                      }}
                                    >
                                      <img src="images/location-01.svg" />
                                      <div>
                                        <div
                                          className={
                                            this.props.cssfile
                                              .activity_autocomplete_name
                                          }
                                        >
                                          {element.search_text}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            {
                              <div className={this.props.cssfile.dropdown_main}>
                                {this.state.autoSuggestCityData.length > 0 && (
                                  <p className={this.props.cssfile.suggestions}>
                                    Suggestions
                                  </p>
                                )}
                                {this.state?.autoSuggestCityData?.length > 0 &&
                                  this.state?.autoSuggestCityData?.map(
                                    (element, index) => {
                                      return (
                                        <div
                                          id={index}
                                          name="ACTIVITY-AUTO-SUGGEST-DATA-LIST"
                                          key={`city-${element.name}`}
                                          className={
                                            this.props.cssfile
                                              .activity_autocomplete_element
                                          }
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({
                                              activityName: element.name,
                                              isAutoSuggestVisible: false,
                                              categoryList: element.category,
                                              monthData: element.month,
                                              selectedAutoSuggestData: element,
                                            });
                                            // this.onClickSetActivityName(element);
                                          }}
                                        >
                                          {/* <img src="images/location-01.svg" /> */}
                                          <span></span>
                                          <div>
                                            <div
                                              className={
                                                this.props.cssfile
                                                  .activity_autocomplete_name
                                              }
                                            >
                                              {element.name}
                                            </div>
                                            {/* <div className={this.props.cssfile.activity_autocomplete_newname}>
                                        23 packages
                                      </div> this will be picked later */}
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                {this.state.noresults && (
                                  <>
                                    <p
                                      className={this.props.cssfile.suggestions}
                                    >
                                      Suggestions
                                    </p>
                                    <div className={this.props.cssfile.no_res}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19"
                                        height="18"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M9.969 3.403V8.83c0 .124.05.242.138.33l3.863 3.836a.467.467 0 0 1-.33.796.475.475 0 0 1-.333-.137L9.445 9.818a1.393 1.393 0 0 1-.412-.988V3.403c0-.257.21-.465.468-.465.259 0 .468.208.468.465zm.747 14.33a8.652 8.652 0 0 1-4.864-.716.463.463 0 0 1-.2-.626.468.468 0 0 1 .63-.2 7.749 7.749 0 0 0 8.368-1.303 7.62 7.62 0 0 0 .525-10.816C12.314.941 7.439.707 4.287 3.55a7.622 7.622 0 0 0-.526 10.816v-1.009c0-.257.21-.465.469-.465.258 0 .468.208.468.465v2.092c0 .257-.21.466-.468.466H2.123a.467.467 0 0 1-.468-.466c0-.256.209-.465.468-.465h.953a8.579 8.579 0 0 1-2.15-4.567C.26 5.712 3.559 1.361 8.296.699c4.735-.66 9.112 2.616 9.779 7.319.665 4.701-2.628 9.048-7.359 9.715z"
                                        ></path>
                                      </svg>
                                      <p>No Result Found</p>
                                    </div>
                                  </>
                                )}
                                {this.state.getrecentSearchListLocal?.length >
                                  0 && (
                                  <div
                                    className={
                                      this.props.cssfile.recent_container
                                    }
                                  >
                                    <div
                                      className={
                                        this.props.cssfile.recent_label
                                      }
                                    >
                                      Recent Search
                                    </div>
                                    {this.state.getrecentSearchListLocal.map(
                                      (el, id) => {
                                        if(el.name) {
                                          return (
                                            <div
                                              className={
                                                this.props.cssfile
                                                  .hotel_autocomplete_element
                                              }
                                              name="RECENT-SEARCH-DIV"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                console.log("name ==", el);
                                                this.setState({
                                                  activityName: el.name,
                                                  isAutoSuggestVisible: false,
                                                  // selectedAutoSuggestData: {
                                                  //   name: el.searchby,
                                                  // }
                                                  selectedAutoSuggestData: el
                                                });
                                              }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="19"
                                                height="18"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  d="M9.969 3.402V8.83c0 .124.05.243.138.33l3.863 3.836a.465.465 0 0 1-.33.795.466.466 0 0 1-.333-.137L9.445 9.818a1.393 1.393 0 0 1-.412-.988V3.402c0-.256.21-.464.468-.464.259 0 .468.208.468.464zm.747 14.331a8.652 8.652 0 0 1-4.864-.716.463.463 0 0 1-.2-.626.468.468 0 0 1 .63-.2 7.749 7.749 0 0 0 8.368-1.303 7.62 7.62 0 0 0 .525-10.816C12.314.941 7.439.708 4.287 3.55a7.622 7.622 0 0 0-.526 10.816v-1.009c0-.257.21-.465.469-.465.258 0 .468.208.468.465v2.092c0 .258-.21.466-.468.466H2.123a.466.466 0 1 1 0-.931h.953a8.582 8.582 0 0 1-2.15-4.567C.26 5.712 3.559 1.361 8.296.699c4.735-.66 9.112 2.616 9.779 7.319.665 4.701-2.628 9.048-7.359 9.715z"
                                                />
                                              </svg>
                                              <div
                                                className={
                                                  this.props.cssfile
                                                    .hotel_autocomplete_name
                                                }
                                              >
                                                <div
                                                  className={
                                                    this.props.cssfile
                                                      .hotel_autocomplete_city
                                                  }
                                                >
                                                  {el.name}
                                                </div>
                                                {/* <div className={this.props.cssfile.hotel_autocomplete_tag}>800 Properties</div> */}
                                              </div>
                                              <div
                                                className={
                                                  this.props.cssfile.hotelnw_city
                                                }
                                              ></div>
                                            </div>
                                          );
                                        }
                                        return null;
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            }
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className={this.props.cssfile.holiday_Dropcont}>
                    <div
                      onClick={() => {
                        this.setState({ isCategoryVisible: true });
                      }}
                      className={this.props.cssfile.catg_lblCont}
                    >
                      <label>Category (Optional)</label>
                    </div>

                    <div
                      className={this.props.cssfile.catg_lblInput}
                      ref={this.categoryRef}
                    >
                      <span className={this.props.cssfile.dropdownPosCat}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="11"
                          onClick={() => {
                            this.setState({ isCategoryVisible: true });
                          }}
                          className={
                            this.state.isCategoryVisible
                              ? this.props.cssfile.arrow_rotate
                              : ""
                          }
                        >
                          <path
                            fill-rule="evenodd"
                            fill="#266AAC"
                            d="m17.373 2.754-7.394 7.457c-.139.14-.3.243-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"
                          />
                        </svg>
                      </span>
                      {/* {JSON.stringify(this.state.categoryValue)} */}
                      <input
                        type="text"
                        tabIndex={2}
                        placeholder="Select Categories"
                        value={uniqueCategories.map((ele) => {
                          return ele.name ? ` ${ele.name}` : "";
                        })}
                        onClick={() => {
                          this.setState({ isCategoryVisible: true });
                        }}
                        onFocus={(e) => {
                          e.stopPropagation();
                          this.onInputFocus("categories");
                        }}
                      />
                      {this.state.isCategoryVisible && (
                        <div className={this.props.cssfile.dropDownlistmain}>
                          <ul
                            className={
                              this.props.cssfile.dropdown_value_container
                            }
                          >
                            {this?.state?.categoryList?.map((ele, id) => {
                              return (
                                <li key={"category" + id}>
                                  <label
                                    className={
                                      this.props.cssfile.checkbox_label
                                    }
                                  >
                                    <span
                                      className={this.props.cssfile.bagCheckbox}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={this.categorySelected(ele)}
                                        className={this.props.cssfile.pointer}
                                        onClick={(e) =>
                                          this.categoryHandler(e, ele)
                                        }
                                      />
                                      <span
                                        className={this.props.cssfile.bagBox}
                                      >
                                        <span
                                          className={
                                            this.props.cssfile.bagCheck
                                          }
                                        ></span>
                                      </span>
                                    </span>
                                    <span
                                      className={this.props.cssfile.advTitle}
                                    >
                                      {ele.name}
                                    </span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`${this.props.cssfile.holiday_Dropcont} ${this.props.cssfile.holiday_Dropcont_last}`}
                  >
                    <div
                      onClick={() => {
                        this.setState({
                          // isMonthVisible: true,
                        });
                      }}
                      className={this.props.cssfile.catg_lblCont}
                    >
                      <label
                        onClick={() => {
                          this.setState({
                            isMonthVisible: true,
                          });
                        }}
                      >
                        Month of Travel (Optional)
                      </label>
                    </div>

                    <div
                      className={this.props.cssfile.catg_lblInput}
                      ref={this.monthRef}
                      onClick={() => {
                        this.setState({
                          // isMonthVisible: true,
                        });
                      }}
                    >
                      <span
                        className={this.props.cssfile.dropdownPos}
                        onClick={() => {
                          this.setState({
                            isMonthVisible: true,
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="11"
                          className={
                            this.state.isMonthVisible
                              ? this.props.cssfile.arrow_rotate
                              : ""
                          }
                        >
                          <path
                            fill-rule="evenodd"
                            fill="#266AAC"
                            d="m17.373 2.754-7.394 7.457c-.139.14-.3.243-.472.311a1.386 1.386 0 0 1-1.513-.309L.599 2.756a1.417 1.417 0 0 1 0-1.991c.546-.55 1.43-.55 1.975 0L8.985 7.23 15.399.763a1.387 1.387 0 0 1 1.974 0c.546.55.546 1.441 0 1.991z"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        tabIndex={3}
                        placeholder="Select Month"
                        value={this.state.monthValue}
                        onClick={() => {
                          this.setState({
                            monthValue: "",
                            isMonthVisible: true,
                          });
                        }}
                        onFocus={(e) => {
                          e.stopPropagation();
                          this.onInputFocus("month");
                        }}
                        onKeyDown={(e) => {
                          console.log("key downn...");
                          // e.stopPropagation();
                          this.keyPressMonth(e.key);
                        }}
                      />
                      {this.state.isMonthVisible && (
                        <div
                          className={this.props.cssfile.dropDownlistmainMonth}
                        >
                          <ul
                            className={
                              this.props.cssfile.dropdown_value_container
                            }
                            id="MONTH-LIST"
                          >
                            {this.state.monthData.map((ele, id) => {
                              return (
                                <li key={"month" + id} id={id}>
                                  <label
                                    id={id}
                                    className={`${this.props.cssfile.checkbox_label} ${this.props.cssfile.option_month}`}
                                    onClick={() => {
                                      this.setState({
                                        monthValue: ele.month,
                                        isMonthVisible: false,
                                      });
                                    }}
                                  >
                                    <span
                                      className={
                                        this.props.cssfile.advTitleDate
                                      }
                                      onClick={() => {
                                        this.setState({
                                          monthValue: ele.month,
                                          isMonthVisible: false,
                                        });
                                      }}
                                    >
                                      {ele.month}
                                    </span>
                                  </label>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div
                    className={`${this.props.cssfile.activity_search_column} ${this.props.cssfile.activity_search_date}`}
                  >
                    <DesktopCalender
                      tabIndex={2}
                      label="Date"
                      styles={this.props.calendarStyles}
                      value={
                        !cf.isValueEmpty(this.state.checkInOutValue)
                          ? this.state.checkInOutValue
                          : ""
                      }
                      placholder="Select the date"
                      onChangeDate={(dateVal) => {
                        this.onChangeCheckInOutValue(dateVal);
                      }}
                      // iconImage={<img src="images/calendar-01.svg" />}
                      startDate={cf.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      startDateLimit={cf.getDateWithFormat(
                        new Date(),
                        this?.props?.dateFormat
                      )}
                      dateFormat={this?.props?.dateFormat}
                      changeVisibility={(bool) => {
                        this.scrollToMyRef();
                        this.setState({
                          isCalendarVisible: bool,
                          isAutoSuggestVisible: false,
                          isDateError: false,
                        });
                      }}
                      isVisible={this.state.isCalendarVisible}
                      doubleSelection={false}
                    />
                    <span>
                      <small></small>
                    </span>
                    {this.state.isDateError && (
                      <div className={this.props.cssfile.errordate}>
                        {"Please select Date"}
                      </div>
                    )}
                  </div> */}

                  {/* <div
                    className={`${this.props.cssfile.activity_search_details_row} ${this.props.cssfile.activity_nw}`}
                  > */}
                  <div className={this.props.cssfile.activity_search_class}>
                    <button
                      tabIndex={4}
                      className={
                        this.state?.btnClicked
                          ? `${this.props.cssfile.activity_search_widget_btn} loading`
                          : this.props.cssfile.activity_search_widget_btn
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        this.onClickSearchActivity();
                      }}
                      onFocus={(e) => {
                        e.stopPropagation();
                        this.onInputFocus("search");
                      }}
                    >
                      {/* <svg width="50px" height="50px" viewBox="0,0,256,256">
                        <g fill="#ffffff" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none">
                        <g transform="scale(5.12,5.12)"><path d="M21,3c-9.39844,0 -17,7.60156 -17,17c0,9.39844 7.60156,17 17,17c3.35547,0 6.46094,-0.98437 9.09375,-2.65625l12.28125,12.28125l4.25,-4.25l-12.125,-12.09375c2.17969,-2.85937 3.5,-6.40234 3.5,-10.28125c0,-9.39844 -7.60156,-17 -17,-17zM21,7c7.19922,0 13,5.80078 13,13c0,7.19922 -5.80078,13 -13,13c-7.19922,0 -13,-5.80078 -13,-13c0,-7.19922 5.80078,-13 13,-13z"></path>
                        </g></g>
                      </svg> */}
                      Search
                    </button>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
